var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vx-card',[(_vm.$apollo.loading)?_c('div',[_c('p',[_vm._v("Cargando...")])]):_c('div',[_c('ValidationObserver',{ref:"validateFormFranchiseUpdate",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col sm:w-1/2 w-full mb-3"},[_c('ValidationProvider',{attrs:{"rules":"required|max:150","name":"Nombre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"label":"Nombre","placeholder":"Nombre"},model:{value:(_vm.alarm.name),callback:function ($$v) {_vm.$set(_vm.alarm, "name", $$v)},expression:"alarm.name"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col sm:w-1/2 w-full mb-3"},[_c('ValidationProvider',{attrs:{"rules":"numeric|required","name":"Tiempo máximo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"label":"Tiempo máximo (minutos)","placeholder":"Tiempo máximo en minutos"},model:{value:(_vm.alarm.max_time),callback:function ($$v) {_vm.$set(_vm.alarm, "max_time", $$v)},expression:"alarm.max_time"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col sm:w-1/2 w-full mb-3"},[_c('small',{staticClass:"font-bold ml-2"},[_vm._v("Color")]),_c('v-swatches',{staticClass:"simple-calendar__swatches",attrs:{"swatches":_vm.swatches},model:{value:(_vm.alarm.color),callback:function ($$v) {_vm.$set(_vm.alarm, "color", $$v)},expression:"alarm.color"}},[_c('div',{staticClass:"simple-calendar__swatches-custom-trigger rounded-lg px-2 py-2",attrs:{"slot":"trigger"},slot:"trigger"},[_c('div',{staticClass:"h-full w-full",style:('background-color:' + _vm.alarm.color)})])])],1),_c('div',{staticClass:"vx-col sm:w-1/2 w-full mb-3"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Estado"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-select',{staticClass:"w-full",attrs:{"label":"Estado","placeholder":"Estado"},model:{value:(_vm.alarm.state.id),callback:function ($$v) {_vm.$set(_vm.alarm.state, "id", $$v)},expression:"alarm.state.id"}},_vm._l((_vm.statuses),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.id,"text":item.name}})}),1),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mt-10"},[_c('div',{staticClass:"vx-col w-full flex justify-end"},[_c('vs-button',{staticClass:"mr-3 mb-3",attrs:{"disabled":invalid},on:{"click":_vm.performMutation}},[_vm._v(" Guardar ")]),_c('vs-button',{staticClass:"mb-3",attrs:{"type":"border"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Descartar ")])],1)])]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }