<template>
  <div>
    <vx-card>
      <div v-if="$apollo.loading">
        <p>Cargando...</p>
      </div>
      <div v-else>
        <ValidationObserver
          ref="validateFormFranchiseUpdate"
          v-slot="{ invalid }"
        >
          <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full mb-3">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required|max:150"
                name="Nombre"
              >
                <vs-input
                  class="w-full"
                  label="Nombre"
                  placeholder="Nombre"
                  v-model="alarm.name"
                />
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="vx-col sm:w-1/2 w-full mb-3">
              <ValidationProvider
                v-slot="{ errors }"
                rules="numeric|required"
                name="Tiempo máximo"
              >
                <vs-input
                  class="w-full"
                  label="Tiempo máximo (minutos)"
                  placeholder="Tiempo máximo en minutos"
                  v-model="alarm.max_time"
                />
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full mb-3">
              <small class="font-bold ml-2">Color</small>
              <v-swatches
                class="simple-calendar__swatches"
                v-model="alarm.color"
                :swatches="swatches"
              >
                <div
                  class="simple-calendar__swatches-custom-trigger rounded-lg px-2 py-2"
                  slot="trigger"
                >
                  <div
                    class="h-full w-full"
                    :style="'background-color:' + alarm.color"
                  ></div>
                </div>
              </v-swatches>
            </div>
            <div class="vx-col sm:w-1/2 w-full mb-3">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="Estado"
              >
                <vs-select
                  class="w-full"
                  label="Estado"
                  placeholder="Estado"
                  v-model="alarm.state.id"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.id"
                    :text="item.name"
                    v-for="(item, index) in statuses"
                  />
                </vs-select>
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vx-row mt-10">
            <div class="vx-col w-full flex justify-end">
              <vs-button
                class="mr-3 mb-3"
                @click="performMutation"
                :disabled="invalid"
              >
                Guardar
              </vs-button>
              <vs-button type="border" class="mb-3" @click="$router.go(-1)">
                Descartar
              </vs-button>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </vx-card>
  </div>
</template>

<script>
import {
  STATE_ALARM,
  ALL_STATES_SERVICES,
  GET_ALARMS,
} from '@/graphql/queries';
import { UPDATE_ALARM, CREATE_ALARM } from '@/graphql/mutations';
export default {
  props: {
    mode: {
      type: String,
      default: 'edit',
    },
  },

  data() {
    return {
      alarm: {
        id: null,
        name: '',
        max_time: null,
        color: '#00D0EB',
        state: {
          id: null,
        },
      },
      statuses: [],
      swatches: [
        '#00FFCC',
        '#33CCFF',
        '#99CCFF',
        '#FFCCFF',
        '#FFCC66',
        '#FFFF99',
        '#CCFF99',
        '#FF9999',
        '#B3B3FF',
        '#C2D6D6',
        '#CCCC00',
        '#FFCC99',
      ],
    };
  },

  apollo: {
    stateAlarm: {
      query: STATE_ALARM,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      skip() {
        return this.mode == 'create';
      },
      result({ data }) {
        this.alarm = data.stateAlarm;
      },
    },
    serviceStatuses: {
      query: ALL_STATES_SERVICES,
      result({ data }) {
        this.statuses = data.serviceStatuses;
      },
    },
  },

  methods: {
    performMutation() {
      if (this.mode == 'edit') {
        this.updateAlarm();
      } else {
        this.createAlarm();
      }
    },

    updateAlarm() {
      this.$apollo
        .mutate({
          mutation: UPDATE_ALARM,
          variables: {
            input: {
              id: this.alarm.id,
              name: this.alarm.name,
              max_time: this.alarm.max_time,
              color: this.alarm.color,
              state: {
                update: {
                  id: this.alarm.state.id,
                },
              },
            },
          },
        })
        .then(() => {
          this.$vs.notify({
            color: 'success',
            title: 'Alarma actualizada!',
            text: 'La alarma fue actualizada correctamente.',
          });
          this.$router.push(`/configuracion/alarmas`);
        })
        .catch((error) => {
          if (error.message.includes('ESTATE_ALREADY_TAKEN')) {
            this.$vs.notify({
              color: 'warning',
              title: 'Alarma no actualizada',
              text: 'Ya existe una alarma creada para el estado seleccionado.',
            });
            return;
          }

          this.$vs.notify({
            color: 'danger',
            title: 'Alarma no actualizada',
            text: 'La alarma no fue actualizada.',
          });
        });
    },

    createAlarm() {
      this.$apollo
        .mutate({
          mutation: CREATE_ALARM,
          variables: {
            input: {
              id: this.alarm.id,
              name: this.alarm.name,
              max_time: this.alarm.max_time,
              color: this.alarm.color,
              state: {
                create: {
                  id: this.alarm.state.id,
                },
              },
            },
          },
          update: (store, { data: { createAlarm } }) => {
            try {
              const data = store.readQuery({
                query: GET_ALARMS,
                variables: {
                  searchText: '',
                  page: 1,
                  first: 7,
                },
              });
              data.stateAlarms.data.push(createAlarm);
              store.writeQuery({
                query: GET_ALARMS,
                variables: {
                  searchText: '',
                  page: 1,
                  first: 7,
                },
                data,
              });
            } catch {
              console.log();
            }
          },
        })
        .then(() => {
          this.$vs.notify({
            color: 'success',
            title: 'Alarma creada!',
            text: 'La alarma fue creada correctamente.',
          });
          this.$router.push(`/configuracion/alarmas`);
        })
        .catch((error) => {
          if (error.message.includes('ESTATE_ALREADY_TAKEN')) {
            this.$vs.notify({
              color: 'warning',
              title: 'Alarma no creada',
              text: 'Ya existe una alarma creada para el estado seleccionado.',
            });
            return;
          }

          this.$vs.notify({
            color: 'danger',
            title: 'Alarma no creada',
            text: 'La alarma no fue actualizada.',
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/vuexy/apps/simple-calendar.scss';

.simple-calendar {
  &__swatches-custom-trigger {
    height: 41px;
    width: 100%;
    border: 1px solid #d7e2e8;
  }
}
.vue-swatches__trigger__wrapper {
  width: 100%;
}
</style>
